<template>
    <div>
        <div class="banner">
            <img src="https://resources.holowits.com/announcement/announcement-banner.png" alt=""/>
        </div>
        <div>
            <div class="slogan">Get familiar with the Announcement for the purchase, support, and servicing of <strong>HOLOWITS</strong> products.</div>
        </div>
        <div class="title">
            <p>Announcement<img src="https://resources.holowits.com/announcement/xuanchuan.png"/></p>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in  list" :key="index">
                <div class="t-img">
                    <img src="https://resources.holowits.com/announcement/ipr_xuanchuan.png" alt="">
                </div>
                <div class="t-label">{{ item.title }}</div>
                <div class="t-link">
                    <a @click="toPage(item.id)">Lear More<i class="el-icon-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getAnctTitles } from '@/api/announcementApi'
export default {
    name: 'AnnouncementMain',

    data() {
        return {
            list: []
        };
    },

    created() {
        this.getAnctTitles()
    },

    methods: {
        async getAnctTitles() {
            const {data: {data, isSuccess}} = await getAnctTitles()
            if (isSuccess) {
                this.list = data
            }
        },
        toPage(id) {
            this.$router.push({
                path: `announcement/${id}/details`
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.banner {
    width: 100%;
    img {
        width: 100%;
    }
}
.slogan {
    font-size: 20px;
    text-align: center;
    padding: 50px 0px;
    width: 610px;
    margin: auto;
    strong {
        font-size: 26px;
        color: #c7000b;
    }
}

.title {
    padding: 50px 0; 
    border-top: 1px solid #ebeef5; 
    border-bottom: 1px solid #ebeef5;
    p {
        font-size: 26px; 
        font-weight: 600; 
        text-align: center;
        img {
            margin-left: 10px; 
            width: 45px; 
            height: 45px;
        }
    }
}
.list {
    width: 980px;
    margin: 0px auto;
    overflow: hidden;
    .item {
        float: left;
        width: 50%;
        margin: 35px 0;
        padding: 0px 80px;
        &:nth-child(odd) {
            border-right: 1px solid #ebeef5;
        }
        .t-img {
            text-align: center;
            img {
                width: 45px;
                height: 45px;
            }
        }
        .t-label {
            text-align: center;
            word-break: break-word;
            font-size: 16px;
            font-weight: 600;
        }
        .t-link {
            margin-top: 20px;
            text-align: center;
            a {
                color: #c7000b;
                cursor: pointer;
            }
        }
    }
}
</style>