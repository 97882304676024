import axiosApi from '@/api/axiosApi';

export const getAnctTitles = () => axiosApi({
    method: 'GET',
    url: '/intserv/announcement/getAnctTitles'
});

export const getTextById = (data) => axiosApi({
    method: 'POST',
    url: '/intserv/announcement/getTextById',
    data,
});